

















import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

@Component
export default class CaseSection extends Vue {
  @Prop(String) readonly title: string | undefined;
  private expanded: boolean = false;
}
