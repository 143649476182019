












































































import {vxm} from "@/store";
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CaseApiFactory, CaseDTO, CaseStatus, Configuration} from "@shared_vue/openapi/caseapi";

import CaseSection from "@/views/widgets/CaseSection.vue"
import {ServiceBusClient, ServiceBusReceiver} from "@azure/service-bus";


@Component({
  components: {CaseSection},
})
export default class ExistingCase extends Vue {

  private auth = vxm.auth;
  @Prop(String) readonly caseid: string|undefined;
  private fullCaseInfo: CaseDTO = <CaseDTO>{};
  private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
  private waitABit = false;
  private serviceBusClient: ServiceBusClient | undefined;
  private receiver: ServiceBusReceiver|undefined;

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit){
      if (typeof this.caseid === "string") {
        this.fetchCase(Number.parseInt(this.caseid))
      }
    }
  }

  mounted(){
    this.configureSB();
    // this.connection = new signalR.HubConnectionBuilder()
    //     .withUrl("http://localhost:5004/casehub")
    //     .withAutomaticReconnect()
    //     .build();
    //
    // if (this.caseid) {
    //   console.log(`case id: ${this.caseid}`)
    //   this.connection.on("CaseUpdate", data => {
    //     console.log('updated!!')
    //     console.log(data); //data is the case id. Fetch just that case and add it to the list :-O :-O :-O
    //     if (typeof this.caseid === "string") {
    //       this.fetchCase(Number.parseInt(this.caseid))
    //     }
    //     //we do NOT want to fetch all. Fetch by ID
    //   });
    // }
    //
    //
    //
    //
    //
    // // this.connection.on("CaseOpenedBy", data => {
    // //   console.log('opened by!!')
    // //   console.log(data); //data is the case id. Fetch just that case and add it to the list :-O :-O :-O
    // //
    // //   //we do NOT want to fetch all. Fetch by ID
    // // });
    //
    // this.connection.start().then(
    //   res => {
    //     if (this.caseid) {
    //       this.connection!.invoke("JoinCaseGroup", this.caseid)  //JoinGroup is C# method name
    //           .catch(err => {
    //             console.log(err);
    //           });
    //     }
    //   }).catch(err => {
    //   console.log(err);
    // });


    if (this.caseid && (typeof this.caseid === "string")) { //note we must not just fetch CaseDTO there is more info than that here
      this.fetchCase(Number.parseInt(this.caseid))
    }

    // setTimeout(()=>{console.log("sendingme");this.connection?.send("CaseOpenedBy","me");}, 5000);

  }

  private myMessageHandler = async (message: any) => { //type this?
    // your code here
    console.log(`message.body: ${message.body}`);
  };
  private myErrorHandler = async (args: any) => {
    console.log(
        `Error occurred with ${args.entityPath} within ${args.fullyQualifiedNamespace}: `,
        args.error
    );
  };

  private async configureSB(){
    let connectionString = await this.caseApi.caseGetConnectionString();
    console.log('sb connectionstring: ' + connectionString.data);
    this.serviceBusClient = new ServiceBusClient(connectionString.data);
    this.receiver = this.serviceBusClient.createReceiver("cases", <string>this.auth.UserId);

    this.receiver.subscribe({
      processMessage: this.myMessageHandler,
      processError: this.myErrorHandler
    });
  }

  private get CaseNo(){
    if (this.fullCaseInfo.id!=undefined){
      return this.fullCaseInfo.caseNumber
    } else {
      return ''
    }
  }

  private get CaseStatus(){
    if (this.fullCaseInfo.id!=undefined){
      return this.fullCaseInfo.status?.toString()
    } else {
      return ''
    }
  }

  private async fetchCase(id: number){
    if (!this.auth.idToken) {
      this.waitABit = true;
      return;
    }
    let response = await this.caseApi.caseGetCaseById(id)
    if (response.status == 200) {
      console.log(response.data)
      this.fullCaseInfo = response.data;
    }
  }
}
